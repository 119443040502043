import { mapActions, mapGetters } from "vuex";
import storageInvoice from "@/modules/profile/components/storage-invoice/index.vue";

export default {
    name: "distributor-storage-leftovers",
    data() {
        return {
            brandSelected: [],
            seriesSelected: [],
            isOpenBrand: false,
        };
    },
    components: { storageInvoice },
    async created() {
        await this.fetchRemainings();
    },
    methods: {
        ...mapActions({
            fetchRemainings: "remainings/GET_REMAININGS",
        }),
    },
    computed: {
        ...mapGetters({
            list: "remainings/list",
            loading: "remainings/loading",
        }),
    },
};
